<template>
  <div class="about-page">
    <div class="about container-fluid">
      <div class="container">
        <div class="about-info-box">
          <div class="title">
            <span>{{ $t('about_page.title') }}</span>
          </div>
          <div class="description">
            <span>{{ $t('about_page.description') }}</span>
          </div>
          <div class="scroll-down">
            <span>{{ $t('about_page.scroll') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="about-list container-fluid">
      <div class="container">
        <div class="list-items">
          <div class="item">
            <div class="image">
              <img src="./img/magic.png" alt="magic-image" />
            </div>
            <div class="text">
              <div class="low-title">
                <span>{{ $t('about_page.lern') }}</span>
              </div>
              <div class="title">
                <span>{{ $t('about_page.title_magic') }}</span>
              </div>
              <div class="description">
                <span>{{ $t('about_page.description_magic') }}</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="text">
              <div class="low-title">
                <span>{{ $t('about_page.lern') }}</span>
              </div>
              <div class="title">
                <span>{{ $t('about_page.title_far') }}</span>
              </div>
              <div class="description">
                <span>{{ $t('about_page.description_far') }}</span>
              </div>
            </div>
            <div class="image">
              <img src="./img/far.png" alt="far-image" />
            </div>
          </div>
          <div class="item">
            <div class="image">
              <img src="./img/future.png" alt="future-image" />
            </div>
            <div class="text">
              <div class="low-title">
                <span>{{ $t('about_page.lern') }}</span>
              </div>
              <div class="title">
                <span>{{ $t('about_page.title_future') }}</span>
              </div>
              <div class="description">
                <span>{{ $t('about_page.description_future') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="smart-trading container-fluid">
      <div class="container">
        <div class="smart-trading-box">
          <div class="title">
            <span>{{ $t('about_page.smart_trade_free') }}</span>
          </div>
          <div class="description">
            <span>{{ $t('about_page.free') }}</span>
          </div>
          <div class="smart-button">
            <button class="start-btn">{{ $t('about_page.start') }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="cards container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 cards_accept">
            <div class="cards__title">
              <span>{{ $t('about_page.accept') }}</span>
            </div>
          </div>
          <div class="col-md-8 col-sm-8 cards__list">
            <div class="skrill">
              <img src="~@assets/img/skrill.png" alt="skrill" />
            </div>
            <div class="visa">
              <img src="~@assets/img/visa.png" alt="visa" />
            </div>
            <div class="mc">
              <img src="~@assets/img/mc.png" alt="master_card" />
            </div>
            <div class="neteller">
              <img src="~@assets/img/neteller.png" alt="neteller" />
            </div>
            <div class="wire">
              <img src="~@assets/img/wire.png" alt="wire" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" src="./styles.scss"></style>
